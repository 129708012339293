<template>
  <v-row justify="center">
    <v-dialog
      transition="scale-transition"
      persistent
      v-model="model"
      max-width="400px"
    >
      <v-card outlined>
        <v-card-title class="title">
          {{ $t("absent.added_manual") }}
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-form ref="form">
            <v-row>
              <v-col cols="12" sm="6" class="d-flex align-center body-1">
                <div>Total {{ roleName }}</div>
              </v-col>
              <v-col cols="12" sm="6">
                <div class="">
                  <TimePicker
                    :initValue="initHours"
                    :label="$t('absent.time_attendance')"
                    @value="v => (modelTime = v)"
                  />
                </div>
              </v-col>
              <v-col cols="12" sm="6">
                <div class="font-weight-bold">
                  {{ idPerson.length }} {{ roleName }}
                </div>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  :label="
                    g_language == 'en'
                      ? absentException.name_eng
                      : absentException.name
                  "
                  v-model="exception"
                  class="pa-0 ma-0"
                  hide-details
                />
              </v-col>
              <v-col cols="12" class="mt-2">
                <v-textarea
                  :label="$t('journal.table.information')"
                  v-model="info"
                  outlined
                  class="mx-2"
                  rows="3"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-layout justify-end>
            <v-btn
              outlined
              small
              dark
              class="mr-2 gradient-error"
              @click="closeDialog"
              >{{ $t("app.cancel") }}</v-btn
            >
            <v-btn
              :loading="loadingBtn"
              outlined
              class="gradient-primary"
              dark
              small
              @click="post"
              >{{ $t("app.save") }}</v-btn
            >
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { updateAbsent } from "@/api/admin/absent/absent";
import { mapGetters } from "vuex";

export default {
  props: {
    roleName: String,
    idPerson: {
      type: Array,
      required: true
    },
    model: {
      type: Boolean,
      required: true
    },
    date: {
      type: String,
      required: true
    },
    absentException: {
      type: Object,
      required: true
    },
    hours: {
      type: String,
      required: true
    },
    typeAbsent: Number
  },
  components: {
    TimePicker: () => import("@/components/TimePicker")
  },
  watch: {
    // hours(data) {
    //   setTimeout(() => {
    //     this.initHours = data;
    //   }, 500);
    // },
    model() {
      this.initHours = "";
      setTimeout(() => {
        this.initHours = this.hours;
      }, 0);
    }
  },
  data() {
    return {
      initHours: null,
      modelTime: null,
      loadingBtn: false,
      exception: false,
      info: ""
    };
  },
  computed: {
    ...mapGetters(["g_language"])
  },
  methods: {
    closeDialog() {
      this.$refs.form.reset();
      this.$emit("isOpen");
    },
    getData() {
      this.$emit("getData");
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isSuccess ? "success" : "error"
      });
    },
    post() {
      if (this.$refs.form.validate()) {
        this.loadingBtn = true;
        return new Promise(resolve => {
          updateAbsent({
            person: this.idPerson,
            type: this.exception ? `${this.absentException.type}` : 4,
            hour: this.date.concat(` ${this.modelTime ?? this.hours}`),
            desc: this.info,
            type_in_or_out: this.typeAbsent === 4 ? "IN" : "OUT"
          })
            .then(res => {
              if (res.data.code) {
                this.snackBar(true, this.$i18n.t("absent.response.success"));
                if (res.data.data && res.data.data.length > 0) {
                  res.data.data.map(r => {
                    this.$socket.emit("serverNotif", {
                      recipient: r.recipient,
                      data: r
                    });
                  });
                }
                this.getData();
                this.confirmDelete = false;
              } else {
                this.snackBar(false, res.data.message);
              }
              this.closeDialog();
              this.getData();
              resolve(res);
            })
            .finally(() => (this.loadingBtn = false));
        });
      }
    }
  }
};
</script>
